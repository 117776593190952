import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Link,
  SvgIcon,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { type SubmitHandler, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import StickyBox from "react-sticky-box";
import DuplicateLesson from "src/components/DuplicateLesson.tsx";
import * as yup from "yup";
import { type InferType } from "yup";
import LessonEditPageDeepDiveKind from "../components/LessonEditPageDeepDiveKind";
import LessonEditPageSection from "../components/LessonEditPageSection";
import LessonEditPageTemplates from "../components/LessonEditPageTemplates";
import LessonContext from "../context/LessonContext";
import { LESSON_PATHS } from "../helpers/const";
import { useOnOffSwitch } from "../helpers/hooks";
import { Sizing, Spacing } from "../types/enum";
import {
  AdminGetLessonDocument,
  LessonKind,
  type LessonModel,
  type SectionModel,
  useAdminEditLessonMutation,
  useAdminGetLessonQuery,
} from "../types/graphql";

/**
 * Schema
 */
const schema = yup.object().shape({
  title: yup.string().required(),
});
type FormValues = InferType<typeof schema>;

const LessonEditPage: React.FC = () => {
  const { spacing } = useTheme();
  const [open, onOpen, onClose] = useOnOffSwitch();
  const [isDuplicateLessonOpen, openDuplicateLesson, closeDuplicateLesson] = useOnOffSwitch();
  const { enqueueSnackbar } = useSnackbar();

  const { sections, lessonId, loading, onCreateSection } = useContext(LessonContext);

  const [editLesson] = useAdminEditLessonMutation({
    refetchQueries: [
      {
        query: AdminGetLessonDocument,
        variables: {
          id: lessonId ?? "",
        },
      },
    ],
  });

  const { data } = useAdminGetLessonQuery({
    fetchPolicy: "network-only",
    variables: {
      id: lessonId ?? "",
    },
    onCompleted: (data) => {
      const defaultValues = { title: data.adminGetLesson.title ?? undefined };
      reset(defaultValues);
    },
  });

  const title = data?.adminGetLesson.title ?? "";
  const kind = data?.adminGetLesson?.kind;
  const topicId = data?.adminGetTopicByLessonId?.id;
  const topicTitle = data?.adminGetTopicByLessonId?.title;
  const lessonPath = kind ? LESSON_PATHS.get(kind) || LESSON_PATHS.get(LessonKind.Overview) : null;
  const href =
    kind && topicId && lessonPath
      ? `${import.meta.env.VITE_APP_URL}/topic/${topicId}/${lessonPath}`
      : "";

  const { handleSubmit, register, reset } = useForm<FormValues>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      title: data?.adminGetLesson?.title ?? undefined,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (form): Promise<void> => {
    if (lessonId) {
      const variables = {
        id: lessonId,
        input: {
          title: form.title,
        },
      };

      try {
        await editLesson({ variables });
        enqueueSnackbar("Lesson name successfully saved", { variant: "success" });
        onClose();
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Box display="flex" alignItems="center" mb={Spacing.m}>
          {topicId ? (
            <Link color="inherit" component={RouterLink} to={`/topic/${topicId}/edit`}>
              <Box mr={Spacing.sm}>
                <ArrowBackIcon />
              </Box>
            </Link>
          ) : null}

          <Box display="flex" alignItems="center">
            <Typography variant="h3" color="textPrimary">
              {title}
            </Typography>
            {kind === LessonKind.Custom ? (
              <Box ml={Spacing.m}>
                <IconButton size="large" onClick={onOpen}>
                  <SvgIcon fontSize="small">
                    <EditIcon />
                  </SvgIcon>
                </IconButton>
              </Box>
            ) : null}
          </Box>

          <Box ml="auto" display="flex">
            <Box mr={Spacing.sm}>
              <Button variant="contained" color="success" onClick={openDuplicateLesson}>
                <ContentCopyIcon />
              </Button>
            </Box>

            <Link color="inherit" href={href} target="_blank">
              <Button variant="contained">View</Button>
            </Link>
          </Box>
        </Box>

        <Grid spacing={Spacing.sm} container>
          <Grid xs={Sizing.ThreeFourths} item>
            {sections.map((section: SectionModel, index: number) => (
              <LessonEditPageSection
                key={section.id}
                index={index}
                topicTitle={topicTitle}
                sectionId={section.id}
                elements={section.elements}
              />
            ))}

            <Box mt={sections.length ? Spacing.sm : undefined}>
              <Button color="primary" variant="contained" fullWidth onClick={onCreateSection}>
                Add Section
              </Button>
            </Box>
          </Grid>

          <Grid xs={Sizing.OneFourth} item>
            {kind === LessonKind.DeepDive ? (
              <StickyBox offsetTop={Number.parseInt(spacing(Spacing.xxl))}>
                <Box mb={Spacing.sm}>
                  <LessonEditPageDeepDiveKind lesson={data?.adminGetLesson as LessonModel} />
                </Box>
              </StickyBox>
            ) : null}
            <StickyBox offsetTop={Number.parseInt(spacing(Spacing.xxl))}>
              <LessonEditPageTemplates />
            </StickyBox>
          </Grid>
        </Grid>
      </DndProvider>

      <Dialog open={open} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Box my={Spacing.s}>
              <Typography variant="body1" color="textPrimary">
                Lesson title
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Enter the name of the lesson
              </Typography>
            </Box>
            <FormControl fullWidth>
              <TextField label="Title" variant="outlined" {...register("title")} />
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </form>
      </Dialog>

      {isDuplicateLessonOpen ? (
        <DuplicateLesson
          isOpen={isDuplicateLessonOpen}
          lessonId={lessonId as string}
          kind={kind as LessonKind}
          currentTopicId={topicId as string}
          close={closeDuplicateLesson}
        />
      ) : null}
    </>
  );
};

export default LessonEditPage;
